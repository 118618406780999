import React, { useEffect } from "react";

import Intro from "./components/Intro";
//import Intro3 from "../banners/Intro3";
import TrendingWork from "./components/TrendingWork";
import Navbar from "./components/TopBar";
import { Grid } from "semantic-ui-react";

import { event } from "../firebase";

function App() {
  useEffect(() => {
    event("visited_app");
  }, []);

  return (
    <Grid style={{ padding: "1em 3em", height: "80vh" }}>
      <Grid.Column>
        <Navbar />
        <Grid
          columns={2}
          textAlign="center"
          verticalAlign="middle"
          doubling
          padded="vertically"
          style={{ height: "90%" }}
        >
          <Grid.Column>
            <Intro />
          </Grid.Column>
          <Grid.Column>
            <TrendingWork />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  );
}

export default App;
