import app from "firebase/app";
import "firebase/analytics";

const config = {
    apiKey: "AIzaSyAp-oObD9j0ZwXZdGWmPkAxBjahZHRU908",
    authDomain: "uid4oe-main.firebaseapp.com",
    databaseURL: "https://uid4oe-main.firebaseio.com",
    projectId: "uid4oe-main",
    storageBucket: "uid4oe-main.appspot.com",
    messagingSenderId: "359706588422",
    appId: "1:359706588422:web:df7a7f84de749b40961a87",
    measurementId: "G-GZ4FEEL3RR"
};

app.initializeApp(config)
export const event = (text) => app.analytics().logEvent(text)

export default app

