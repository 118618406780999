import React, { useState, useEffect } from "react";
import { content } from "../data";
import Confetti from "../atoms/Confetti";
import { Card, Grid, Header, Icon, Label } from "semantic-ui-react";

const Intro = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => setActive(true), 250);
  }, []);

  return (
    <>
      <Confetti active={active} setActive={setActive} />
      <div style={{ padding: "0.5em" }}>
        <Grid>
          <Card fluid style={{ boxShadow: "none", background: "transparent" }}>
            <Card.Content>
              <Header style={{ fontSize: "3rem", marginBottom: "0" }}>
                Hi, I'm
              </Header>
              <Header style={{ fontSize: "4rem", marginTop: "0" }}>
                {content.header}
              </Header>
              {content.subHeader.map((i, index) => (
                <Label
                  size="small"
                  basic
                  style={{
                    fontSize: "3.15em",
                    margin: "0.2em",
                    padding: "0.2em",
                  }}
                >
                  {i}
                </Label>
              ))}
            </Card.Content>
            <Card.Content>
              {content.intro}
              <div style={{ padding: "0 0 0.5em 0" }}>
                {content.introStack.map((i, index) => (
                  <Label
                    key={index}
                    size="small"
                    color="red"
                    style={{
                      margin: "0.25em",
                      fontSize: "1em",
                    }}
                    content={i}
                  />
                ))}
              </div>
              <div style={{ fontSize: "1.15em" }}>{content.about}</div>
            </Card.Content>
            <Grid.Row className="arrowDiv" textAlign="center">
              <Icon
                name="chevron down"
                size="large"
                className="arrowDownIcon"
              />
            </Grid.Row>
          </Card>
        </Grid>
      </div>
    </>
  );
};

export default Intro;
